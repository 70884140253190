/**
 * Main project app script!
 *
 * All script functionality should be in this file.
 *
 * NOTE: jQuery by default is NOT supported! Please make sure to follow ES6 paradigms and create vanilla JS
 * wherever possible. jQuery is included for the sake of plugins that we rely on such as Gravity Forms.
 * Refer to the document at https://wiki.posturedev.com for further information.
 **/

import jquery from "jquery";

// prevents platform from removing parent utility imports
// import utilities from "Parent/js/utilities/utilities";

// utilities;

//Modules
import Globals from "./globals";
import Contact from "./contact";
import Blog from "./blog";
import Work from "./work";

// Attach the scripts after the website is up and running
document.addEventListener("DOMContentLoaded", () => {
	// Remove and uncomment the enqueue script in functions.php if not needed
	window.$ = jQuery();

	const globals = new Globals();
	const contact = new Contact();
	const blog = new Blog();
	const work = new Work();
});
