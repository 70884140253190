import $ from 'jquery';

class Contact {

    constructor() {
        //Check on page load if someone's loading the contact modal from /contact page.
        const page = window.location.hash.substr(1);
        const backButton = document.getElementById('contactBack');

        this.resetForms();

        let gformWrappers = this.getgFormWrappers();

        if (page === "contact") {
            setTimeout(this.openContactModal("contactHome"), 300);
        }

        //Attach event listener to contact button to trigger default form.
        const contactButton = document.querySelector('#siteContact .control');

        contactButton.addEventListener("click", e => {
            if(!document.querySelector('.control').classList.contains('active')) {
                this.openContactModal("contactHome");
            }
        });

        //Add extra class to gfield checkboxes
        let gformCheckboxes = document.getElementsByClassName('gfield_checkbox');
        Array.from(gformCheckboxes).forEach((gFormCheckbox) => {
            gFormCheckbox.classList.add('button-group');
        });

        //Attach event listeners to all contact-trigger classes.
        let contactLinks = document.getElementsByClassName("contact-trigger");

        Array.from(contactLinks).forEach((contactLink) => {
            contactLink.addEventListener('click', e => {
                this.openContactModal("contactHome");
            });
        });

        //Attach event listeners to all career-trigger classes.
        let careerLinks = document.getElementsByClassName("career-trigger");

        Array.from(careerLinks).forEach((careerLink) => {
            careerLink.addEventListener('click', e => {
                this.openContactModal("joinTeam");
            });
        });

        // Trigger Gravity Forms confirmation on form submit.
        // There is no way to trigger this hook without jQuery :(
        $(document).bind('gform_confirmation_loaded', function(event, formId){
            this.displayConfirmation(event, formId);
        });

        //Start A Project
        const startProjectButton = document.getElementById('startProject');

        startProjectButton.addEventListener("click", e => {
            backButton.setAttribute('data-selected-form', 'startProjectForm');

            this.loadInnerContent("startProject");
        });

        //Get In Touch
        const getInTouchButton = document.getElementById('getInTouch');

        getInTouchButton.addEventListener("click", e => {

            backButton.setAttribute('data-selected-form', 'getInTouchForm');

            this.loadInnerContent("getInTouch");
        });

        //Join Team
        const joinTeamButton = document.getElementById('joinTeam');

        joinTeamButton.addEventListener("click", e => {

            backButton.setAttribute('data-selected-form', 'joinTeamForm');

            this.loadInnerContent("joinTeam");
        });


        //Back button event listener
        backButton.addEventListener('click', e => {

            //Get the selected form and then reset the selection back to false.
            const selectedForm = backButton.getAttribute('data-selected-form');
            const selectedFormWrapper = document.querySelector('#' + selectedForm + ' .gform_wrapper');
            backButton.setAttribute('data-selected-form', '');

            let contactCanvasHeader = document.querySelector('.contact-canvas h2.header-title');
            const contactTitle = contactCanvasHeader.getAttribute('data-header-title');

            setTimeout(
                () => {
                    backButton.classList.add('fadeOut');

                    if(!!selectedFormWrapper === false) {
                        const reloadButton = document.querySelector('#' + selectedForm + " .gprl-reload-link");
                        reloadButton.click();
                    }

                    setTimeout(
                        () => {
                            let gformWrappers = this.getgFormWrappers();
                            let formContainers = this.getFormContainers();


                            gformWrappers.forEach((wrapper) => {
                                if(wrapper.classList.contains('active')) {
                                    wrapper.style.opacity = 1;
                                    wrapper.classList.add('fadeOut');
                                }
                            });
                            formContainers.forEach((container) => {
                                if(!!container === true) {
                                    container.classList.add = 'fadeOut';
                                }
                            });

                            this.updateHeaderText(contactTitle, false);

                            setTimeout(() => {
                                backButton.classList.remove('active', 'fadeOut');
                                formContainers.forEach((container) => {
                                    if(!!container === true) {
                                        container.style.display = 'none';
                                        container.classList.remove('fadeOut');
                                    }
                                });

                                this.loadInnerContent("contactHome");

                            }, 600);
                        }, 200
                    );
                }, 100
            );
        });

        //Close Button Listener
        let closeButton = document.querySelector('.close-control');

        closeButton.addEventListener("click", e => {

            //Get the selected form and then reset the selection back to false.
            const selectedForm = backButton.getAttribute('data-selected-form');

            if(!!selectedForm === true) {
                const selectedFormWrapper = document.querySelector('#' + selectedForm + ' .gform_wrapper');
                backButton.setAttribute('data-selected-form', '');

                if(!!selectedFormWrapper === false) {
                    const reloadButton = document.querySelector('#' + selectedForm + " .gprl-reload-link");
                    reloadButton.click();
                }
            }

            let contactCanvas = document.querySelector('.contact-canvas');
            let control = document.querySelector('.control');
            let gformWrappers = this.getgFormWrappers();
            let formContainers = this.getFormContainers();
            let header = document.querySelector(".contact-canvas h2.header-title");
            let formButtons = this.getFormButtons();
            let contactFooter = document.getElementById("contactInfo");

            contactFooter.classList.add('fadeOut');

            Array.from(formButtons).forEach((formButton) => {
                formButton.classList.add('fadeOut');
            });

            setTimeout(function() {

                header.classList.add('fadeOut');

                contactCanvas.classList.remove('visible');
                contactCanvas.classList.remove('open');

                gformWrappers.forEach((wrapper) => {
                    wrapper.classList.add('fadeOut');
                });

                setTimeout(function() {
                    document.getElementById('mainForm').classList.remove('active');
                    document.getElementById('contactBack').classList.remove('active');

                    formContainers.forEach((container) => {
                        if(!!container === true) {
                            container.style.display = 'none';
                        }
                    });
                    gformWrappers.forEach((wrapper) => {
                        wrapper.style.opacity = 0;
                        wrapper.classList.remove('active', 'fadeOut');
                    });

                    header.classList.remove('fadeOut', 'active');

                    setTimeout(function() {
                        document.body.classList.remove('contact-active');
                    }, 100);

                    setTimeout(function() {
                        control.classList.remove('active');
                    }, 500);

                    setTimeout(function() {
                        contactFooter.classList.remove('active');
                        contactFooter.classList.remove('fadeOut');
                        contactCanvas.classList.remove('active');

                    }, 500);

                }, 500);

            }, 1000);
        });
    }

    //Open the contact modal and load the proper form based on contactType.
    openContactModal(contactType) {
        const contactCanvas = document.querySelector('.contact-canvas');

        setTimeout(() => {
            contactCanvas.classList.add('active');
			document.querySelector('.control').classList.add('active');
            document.querySelector('.contact-canvas').classList.add('visible');

            setTimeout(() => {
                document.body.classList.add('contact-active');

                setTimeout(() => {
                    this.loadInnerContent(contactType);
                    contactCanvas.classList.add('open');
                }, 300);
            }, 300);
        }, 300);

		//Hotjar tracking event;
		hj('trigger', 'contact_modal');
    }

    loadInnerContent(contactType) {
        let formButtons = this.getFormButtons();
        if(contactType != "contactHome") {
            Array.from(formButtons).forEach((formButton) => {
                formButton.classList.add('fadeOut');
            });
        } else {
            Array.from(formButtons).forEach((formButton) => {
                formButton.classList.remove('fadeOut');
            });
        }

        switch(contactType) {
            case "joinTeam":
                setTimeout(this.updateHeaderText('Join Our Team', 'joinTeam'), 2000);
                break;
            case "getInTouch":
                setTimeout(this.updateHeaderText('Get In Touch', 'getInTouch'), 2000);
                break;
            case "startProject":
                setTimeout(this.updateHeaderText('Start a Project', 'startProject'), 2000);
                break;
            case "contactHome":
            default:
              this.contactHome();
        }
    }

    //Gforms confirmation handler
    displayConfirmation(event, formId) {

        let header = document.querySelector(".contact-canvas h2.header-title");

        switch(formId) {
            case formId === 1:
                let touchForm = document.getElementById("getInTouchForm");
                touchForm.parentNode.removeChild(touchForm);
                break;

            case formId === 2:
                let startForm = document.getElementById("startProjectForm");
                startForm.parentNode.removeChild(startForm);
                break;

            case formId === 6:
                let teamForm = document.getElementById("joinTeamForm");
                startForm.parentNode.removeChild(teamForm);
                break;
            default:
        }

        header.innerHTML = "Thanks for contacting us! We will get in touch with you shortly.";

    }

    contactHome() {
        let contactCanvasHeader = document.querySelector('.contact-canvas h2.header-title');
        const contactTitle = contactCanvasHeader.getAttribute('data-header-title');
        contactCanvasHeader.innerHTML = contactTitle;
        contactCanvasHeader.classList.add('active');

        const contactFooter = document.getElementById("contactInfo");
        contactFooter.classList.add('active');

        let gformWrappers = this.getgFormWrappers();
        let formContainers = this.getFormContainers();

        gformWrappers.forEach((wrapper) => {
            wrapper.style.opacity = 0;
            wrapper.classList.remove('active', 'fadeOut');
        });

        formContainers.forEach((container) => {
            if(!!container === true) {
                container.style.display = 'none';
            }
        });

        document.getElementById('mainForm').classList.add('active');
    }

    joinTeam() {
        setTimeout(() => {
            document.getElementById('mainForm').classList.remove('active');
            document.getElementById('joinTeamForm').style.display = "block";
            document.getElementById('gform_wrapper_6').classList.add('active');
            document.getElementById('contactBack').classList.add('active');
            let contactCanvasHeader = document.querySelector('.contact-canvas h2.header-title');
            contactCanvasHeader.classList.add('header-title');
            contactCanvasHeader.classList.add('active');

            if(!!document.querySelector('.career-trigger') == true) {
                const selectInput = document.getElementById('input_6_9');
                const applyButton = document.getElementById('applyButton');
                let selected = applyButton.getAttribute('data-position');
                selected.replace('–', '-');

                console.log(selected);
                selectInput.value = selected;
            }

        }, 1200);
    }

    getInTouch() {
        setTimeout(() => {
            document.getElementById('mainForm').classList.remove('active');
            document.getElementById('getInTouchForm').style.display = "block";
            document.getElementById('gform_wrapper_1').classList.add('active');
            document.getElementById('contactBack').classList.add('active');
        }, 1200);
    }

    startProject() {
        setTimeout(() => {
            document.getElementById('mainForm').classList.remove('active');
            document.getElementById('startProjectForm').style.display = "block";
            document.getElementById('gform_wrapper_2').classList.add('active');
            document.getElementById('contactBack').classList.add('active');
        }, 1200);
    }

    getgFormWrappers() {
        const gformWrappers = [document.getElementById('gform_wrapper_1'), document.getElementById('gform_wrapper_2'), document.getElementById('gform_wrapper_6')];

        return gformWrappers;
    }

    getFormContainers() {
        const formContainers = [document.getElementById("getInTouchForm"), document.getElementById("startProjectForm"), document.getElementById("joinTeamForm")];

        return formContainers;
    }

    getFormButtons() {
        const formButtons = document.getElementsByClassName("contact-button");

        return formButtons;
    }

    updateHeaderText(text, callback) {

        let header = document.querySelector(".contact-canvas h2.header-title");

        if(!!callback === true) {
            this[callback]();
        }

        setTimeout(
            () => {
                header.classList.add('fadeOut');
                setTimeout(
                    () => {
                        header.innerHTML = text;
                        header.classList.remove('fadeOut');
                    }
                    , 600
                );
            }
            , 100
        );

        return;
    }

    //Clear all values on page load.
    resetForms() {
        document.querySelector("#startProjectForm form").reset();
        document.querySelector("#getInTouchForm form").reset();
        document.querySelector("#joinTeamForm form").reset();
    }

}

export default Contact;