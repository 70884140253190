class Blog {
	constructor() {
		const loadMoreButton = document.getElementById("loadMoreButton");

		if (loadMoreButton) {
			this.loadMoreButton();
		}

		// this.addCatColors();
	}

	loadMorePosts(offSet, limit) {
		const axios = require("axios");

		let loadMoreButton = document.getElementById("loadMoreButton");
		let blogWrapper = document.getElementById("blogWrapper");
		let paged = loadMoreButton.getAttribute("data-paged");

		loadMoreButton.remove();
		blogWrapper.insertAdjacentHTML(
			"beforeend",
			'<img id="loader" src="/wp-content/themes/posturepress2-child/dist/images/loader.svg" alt="Loading..." />'
		);

		let loading = document.getElementById("loader");

		let data = new FormData();
		data.append("action", "load_blog_more");
		data.append("paged", paged);

		axios.post("/wp-admin/admin-ajax.php", data).then((response) => {
			if (response.data) {
				loading.remove();

				blogWrapper.insertAdjacentHTML("beforeend", response.data);

				this.loadMoreButton();
			} else {
				loading.remove();

				loadMoreButton.remove();
			}
		});
	}

	loadMoreButton() {
		if (!!document.getElementById("loadMoreButton") === true) {
			const loadMoreButton = document.getElementById("loadMoreButton");

			loadMoreButton.addEventListener("click", (e) => {
				this.loadMorePosts();
			});
		}
	}

	// addCatColors() {
	// 	// console.log("addCatColors");
	// 	// wait for the checkboxes to load
	// 	//

	// 	const categories = document.querySelectorAll(".category-filters");

	// 	if (categories) {
	// 		waitForElement(
	// 			".facetwp-checkbox",
	// 			function () {
	// 				jQuery(".facetwp-checkbox").each(function () {
	// 					var color = jQuery(this).attr("data-color");
	// 					if (color) {
	// 						console.log(color);
	// 					}

	// 					// insert a div with the color as the background inside this element
	// 					jQuery(this).prepend('<div class="color" style="background-color: ' + color + ';"></div>');
	// 				});
	// 			},
	// 			3000
	// 		);
	// 	}
	// }
}

export default Blog;

// function waitForElement(selector, callback, timeout) {
// 	var intervalTime = 50; // milliseconds to wait between checks, adjust as needed
// 	var elapsedTime = 0;
// 	var interval = setInterval(function () {
// 		if (jQuery(selector).length) {
// 			clearInterval(interval);
// 			callback();
// 		} else if (timeout && elapsedTime >= timeout) {
// 			clearInterval(interval);
// 		}
// 		elapsedTime += intervalTime;
// 	}, intervalTime);
// }
