class Work {

    constructor() {

        if(!!document.getElementById('workProjectContent') === true) {
            const workProjectContent = document.getElementById('workProjectContent');
            const limit = workProjectContent.getAttribute('data-project-limit');
            const categoryToggle = document.getElementById('categoryToggle');
            const categoryToggleList = document.querySelector('.category-toggle');

            setTimeout(() => {
                categoryToggle.classList.toggle('active');
                document.querySelector('.category-toggle').classList.toggle('active');
                setTimeout(() => {
                    categoryToggle.classList.toggle('active');
                    document.querySelector('.category-toggle').classList.toggle('active');
                }, 2000);
            }, 1000);


            //On page load, check if we're loading a specific cateogry or else
            //load ALL projects.
            const urlLoadClass = this.getUrlVars()["loadClass"];
            const urlLoad = this.getUrlVars()["load"];

            if(urlLoad) {
                this.loadProjects(0, limit, decodeURI(urlLoad));
                document.querySelector("a.All").classList.remove('active');
                document.querySelector("a." + urlLoadClass).classList.add('active');;
            } else {
                this.loadProjects(0, limit, 'All');
            }
            categoryToggle.addEventListener("click", e => {
                e.preventDefault();

                categoryToggle.classList.toggle('active');
                document.querySelector('.category-toggle').classList.toggle('active');

            });

            //Attach event listeners to all category-toggle classes.
            let categoryToggleLinks = document.querySelectorAll(".category-toggle li a");

            Array.from(categoryToggleLinks).forEach((categoryToggleLink) => {
                categoryToggleLink.addEventListener('click', e => {

                    e.preventDefault();

                    const workProjectOptions = document.getElementById('workProjectOptions');
                    const workProjectLoadCategory = e.target;
                    const projectType = workProjectLoadCategory.getAttribute('data-project-type');

                    if(categoryToggleList.classList.contains('active')) {
                        categoryToggleList.classList.remove('active');
                    }

                    let categoryToggles = document.getElementsByClassName("category-toggle");
                    Array.from(categoryToggles).forEach((categoryToggle) => {
                        if(categoryToggle.classList.contains('active')) {
                            categoryToggle.classList.remove('active');
                        }
                    });

                    let workProjectLinks = workProjectOptions.querySelectorAll('a');
                    Array.from(workProjectLinks).forEach((workProjectLink) => {
                        if(workProjectLink.classList.contains('active')) {
                            workProjectLink.classList.remove('active');
                        }
                    });

                    workProjectLoadCategory.classList.add('active');

                    workProjectContent.innerHTML = "";

                    this.loadProjects(0, limit, projectType);
                });
            });
        }
    }

    changeProjects() {
        let projectDetails = [];
        const offSet = document.querySelectorAll('.work-project-container').length;

        projectDetails['offSet'] = offSet;
        projectDetails['projectType'] = document.getElementById('loadMoreButton').getAttribute('data-project-type');

        return(projectDetails);
    }

    loadProjects(offSet, limit, projectType) {

        const axios = require('axios');

        let loadMoreButton = document.getElementById('loadMoreButton');
        let workProjectContent = document.getElementById('workProjectContent');

        if(loadMoreButton) {
            loadMoreButton.remove();
        }

        workProjectContent.insertAdjacentHTML('beforeend', '<img id="loader" src="/wp-content/themes/posturepress2-child/dist/images/loader.svg" alt="Loading..." />');

        let loading = document.getElementById('loader');

        let data = new FormData;
        data.append('limit', limit);
        data.append('offSet', offSet);
        data.append('projectType', projectType);
        data.append('action', 'load_work_projects');

        axios.post("/wp-admin/admin-ajax.php", data).then((response) => {
            if ( response.data ) {

                loading.remove();

                workProjectContent.insertAdjacentHTML('beforeend', response.data);

                this.loadMoreButton();

                this.setupContentLinks();
              }
              else {
                loading.remove();

                loadMoreButton.remove();
              }
        });
    }

    getUrlVars() {
		var vars = [], hash;
		var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
		for(var i = 0; i < hashes.length; i++)
		{
				hash = hashes[i].split('=');
				vars.push(hash[0]);
				vars[hash[0]] = hash[1];
		}
		return vars;
	}

    findParentByClass(node, cls) {
        while (node && !node.classList.contains(cls)) {
            node = node.parentNode;
        }
        return node;
    }

    loadMoreButton() {
        if(!!document.getElementById('loadMoreButton') === true) {
            const loadMoreButton = document.getElementById('loadMoreButton');
            const limit = workProjectContent.getAttribute('data-project-limit');
            const offSet = document.querySelectorAll('.work-project-container').length;
            const projectType = document.querySelector('.load-category.active').getAttribute('data-project-type');

            loadMoreButton.addEventListener("click", e => {
                this.loadProjects(offSet, limit, projectType);
            });
        }
    }

    setupContentLinks() {
        let showContentLinks = document.querySelectorAll(".show-content");

        Array.from(showContentLinks).forEach((showContentLink) => {
            showContentLink.addEventListener('click', e => {
                e.preventDefault();

                const parent = e.target.parentNode;
                let toggle = false;

                if(!parent.classList.contains('active')) {
                    toggle = true;
                }

                document.querySelectorAll('.work-project-container').forEach(e => e.classList.remove('active'));

                if(toggle === true) {
                    parent.classList.toggle('active');
                }

            });
        });
    }

}

export default Work;