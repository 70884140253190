import Swiper, { Navigation } from "Parent/js/modules/swiper/swiper";
import A11yDialog from "a11y-dialog";

Swiper.use([Navigation]);

class Globals {
	constructor() {
		const ieVersion = this.getIEVersion();

		const dialogEl = document.getElementById("posturePopup");

		if (typeof dialogEl != "undefined" && dialogEl != null) {
			let dialog = new A11yDialog(dialogEl);

			const cookie = this.getCookie("popup-set");

			if (!cookie) {
				this.setCookie("popup-set", "true", 60);

				dialog.show();
			}
		}

		this.blog_slider_el = document.querySelector(".blog-slider");
		this.blog_slider = null;

		if (this.blog_slider_el) {
			this.runBlogSlider();
		}

		//Internet Explorer Detection
		if (ieVersion > 0 && ieVersion < 12) {
			if (window.location.pathname != "/outdated-browser-detected/") {
				window.location = "/outdated-browser-detected/";
			}
		}

		//Change particle colors on hover.
		let particles = document.getElementsByClassName("particle");

		Array.from(particles).forEach((particle) => {
			particle.addEventListener("mouseleave", (e) => {
				this.changeParticleColor(e, particle);
			});
		});

		//Toggle menu show and hide.
		let menuOpen = document.getElementById("menuToggle");
		let menuClose = document.getElementById("overlayContainer");

		menuOpen.addEventListener("click", (e) => {
			document.body.classList.toggle("nav-is-visible");
			setTimeout(() => {
				menuClose.classList.add("active");
			}, 500);
		});

		menuClose.addEventListener("click", (e) => {
			this.closeMenu();
		});
	}

	closeMenu() {
		let menuClose = document.getElementById("overlayContainer");
		menuClose.classList.remove("active");
		setTimeout(() => {
			document.body.classList.toggle("nav-is-visible");
		}, 300);
	}

	changeParticleColor(e, particle) {
		const curBeforeColor = particle.getAttribute("data-beforecolor");
		const curAfterColor = particle.getAttribute("data-aftercolor");
		let newColor = this.getColor(curBeforeColor, curAfterColor);

		setTimeout(() => {
			particle.classList.remove("after-" + curAfterColor);
			particle.classList.add("after-" + newColor);
			particle.dataset.aftercolor = newColor;
		}, 500);
	}

	getColor(curBefore, curAfter) {
		var colors = ["blue", "beige", "orange", "gray", "dark-gray", "light-gray"];
		colors.splice(colors.indexOf(curBefore), 1);
		colors.splice(colors.indexOf(curAfter), 1);

		return colors[Math.floor(Math.random() * colors.length)];
	}

	getIEVersion() {
		var sAgent = window.navigator.userAgent;
		var Idx = sAgent.indexOf("MSIE");

		// If IE, return version number.
		if (Idx > 0) return parseInt(sAgent.substring(Idx + 5, sAgent.indexOf(".", Idx)));
		// If IE 11 then look for Updated user agent string.
		else if (!!navigator.userAgent.match(/Trident\/7\./)) return 11;
		else return 0; //It is not IE
	}

	runBlogSlider() {
		this.blog_slider = new Swiper(this.blog_slider_el, {
			autoHeight: true,
			slidesPerView: 3,
			spaceBetween: 30,
			pagination: false,
			breakpoints: {
				0: {
					slidesPerView: 1,
				},
				641: {
					slidesPerView: 1,
				},
				1024: {
					slidesPerView: 3,
				},
			},
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
		});
	}

	getCookie(cName) {
		const name = cName + "=";
		const cDecoded = decodeURIComponent(document.cookie); //to be careful
		const cArr = cDecoded.split("; ");
		let res;
		cArr.forEach((val) => {
			if (val.indexOf(name) === 0) res = val.substring(name.length);
		});
		return res;
	}

	setCookie(cName, cValue, cTime) {
		let date = new Date();
		date.setTime(date.getTime() + cTime * 60 * 1000);
		const expires = "expires=" + date.toUTCString();
		document.cookie = cName + "=" + cValue + "; " + expires + "; SameSite=Strict; path=/ ";
	}
}

export default Globals;
